(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name neo.services.factory:ChampionshipCalendar
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('ChampionshipCalendar', ChampionshipCalendar);

  function ChampionshipCalendar($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/championships/:championshipId/calendar', {championshipId: '@_championshipId'});
  }
}());
